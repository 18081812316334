<template>
  <base-section id="business-partner-landing">
    <base-section-heading :title="$t('business_partner_landing_headline')">
    </base-section-heading>

    <v-container>
      <div v-html="$t('business_partner_landing_text')"></div>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: 'SectionBusinessPartnerLanding',

  data: () => ({}),
}
</script>
